@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');

@import 'animate.css';

@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Patua+One&display=swap');

@import 'aos/dist/aos.css'

@font-face {
  font-family: 'Sans Serif';
  src: url('./assets/font/Sanseriffic.otf')
}

@layer base {
  body {
    @apply font-primary text-lg font-medium;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @apply font-primary;
  }
  .h1 {
    @apply font-sans text-6xl lg:text-8xl font-bold text-white;
  }
  .h2 {
    @apply font-black text-2xl mb-4 leading-[1.1] md:text-3xl;
  }
  .h3 {
    @apply font-extrabold italic text-xl md:text-2xl;
  }
  .h4 {
    @apply font-extrabold italic text-[26px] text-white;
  }
  .h5 {
    @apply font-medium text-[22px] text-white;
  }
  .pretitle {
    @apply font-secondary font-bold text-base uppercase tracking-[-0.04em] text-accent mb-2;
  }
  .btnCustom {
    @apply h-14 rounded-full bg-blu-logo px-[42px] text-white text-base font-secondary font-semibold  hover:bg-white hover:text-black transition-all duration-300 flex justify-center items-center;
  }
  .input {
    @apply h-[56px] w-[316px] outline-none pl-[30px] rounded-full border-2 border-gray-200 font-secondary font-semibold text-base;
  }
}

/* disable buttons in react slide show */
.react-slideshow-container > button {
  display: none !important;
}

.leaflet-container {
  /* width: 100%;
  height: 100%; */
  margin: 10px 10px;
}

.leaflet-control-attribution {
  display: none;
}

/* {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
